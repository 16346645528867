const React = require('react');
const { useState, useMemo, useEffect } = require('react');
const EditTimeFrame = require('EditTimeFrame');
const TimeFrame = require('TimeFrame');
const addNewIcon = require('images/icons/edit_field/icon_plus.svg');
const { useTranslation, withTranslation } = require('react-i18next');
const moment = require('moment');
const { unitDisplayName } = require('unitsManager');
const { connect, useSelector } = require('react-redux');
const {
  validateChangedTimeFrame,
  calculateMinMax,
} = require('timeFramesManager');
const $ = require('jquery');

const IrrigationTimeFrame = ({
  onIrrigationTimeFramesChange,
  initialTimeFrames,
  userData,
  fieldIrrigationSystem,
  fieldSoilType,
  soil_data,
  irrigationStartDate,
}) => {
  const [addTimeFrameBtnClicked, setAddTimeFrameBtnClicked] = useState(false);
  const [timeFrames, setTimeFrames] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [dayChangeError, setDayChangeError] = useState(false);
  const [startHourChangeError, setStartHourChangeError] = useState(false);
  const [endHourChangeError, setEndHourChangeError] = useState(false);
  let userUnits =
    userData && 'length' in userData ? unitDisplayName(userData.length) : '';
  const soilData = useSelector((state) => state.soil_data.soil_data);
  const { t } = useTranslation();

  useEffect(() => {
    const timeFramesArray = Object.values(initialTimeFrames || {});
    setTimeFrames(timeFramesArray);
  }, [initialTimeFrames]);

  useEffect(() => {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({ offset: '0,6' });
    });
  }, []);

  const addNewTimeFrameHandler = () => {
    setAddTimeFrameBtnClicked(true);
  };

  const HandleTimeFrameChange = (timeFrames, newTimeFrame, validateFrom) => {
    if (validateFrom === 'edit') {
      // remove old timeframe from timeframes and send to validate edit
      let timeFramesDup = [...timeFrames];
      let timeFrameIndex = timeFrames.findIndex(
        (obj) => obj.id === newTimeFrame.id,
      );
      timeFramesDup.splice(timeFrameIndex, 1);
      return validateChangedTimeFrame(timeFramesDup, newTimeFrame);
    }
    return validateChangedTimeFrame(timeFrames, newTimeFrame);
  };

  const addTimeFrame = (
    startDay,
    startHour,
    endHour,
    min,
    max,
    startDate,
    endDate,
    name,
  ) => {
    const timeFrame = {
      id: startDay + startHour + endHour,
      day: startDay,
      start_hour: startHour,
      end_hour: endHour,
      min: min,
      max: max,
      active_period_start_date: startDate,
      active_period_end_date: endDate,
      name: name,
    };
    let timeFramesDup = [...timeFrames];
    timeFramesDup.push(timeFrame);
    if (!HandleTimeFrameChange(timeFrames, timeFrame, 'new')) {
      return t('time_frame_conflict');
    }
    timeFrames.push(timeFrame);
    setAddTimeFrameBtnClicked(!addTimeFrameBtnClicked);
    onIrrigationTimeFramesChange(timeFrames);
    return '';
  };

  const removeTimeFrame = (id) => {
    setTimeFrames((prevState) => {
      let timeFrameIndex = prevState.findIndex((obj) => obj.id === id);
      prevState.splice(timeFrameIndex, 1);
      return [...prevState];
    });
    onIrrigationTimeFramesChange(timeFrames);
  };

  const handleChangeTimeFrameDay = (id, day) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.day = day;
    if (day) {
      if (!HandleTimeFrameChange(timeFrames, item, 'edit')) {
        setDayChangeError(true);
        return true;
      }
      timeFramesDup[itemIndex] = item;
      setTimeFrames(timeFramesDup);
      onIrrigationTimeFramesChange(timeFramesDup);
      return false;
    }
  };
  const handleChangeTimeFrameStartHour = (id, startHour) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.start_hour = startHour;
    if (item.day) {
      if (!HandleTimeFrameChange(timeFrames, item, 'edit')) {
        setStartHourChangeError(true);
        return true;
      }
      timeFramesDup[itemIndex] = item;
      setTimeFrames(timeFramesDup);
      onIrrigationTimeFramesChange(timeFramesDup);
      return false;
    }
  };

  const setSourceContent = (
    fieldIrrigationSystem,
    fieldSoilType,
    soilData,
    userUnits,
    timeFrame = {},
  ) => {
    let minTooltipContent = '';
    let maxTooltipContent = '';
    let {
      minPlaceHolder,
      maxPlaceHolder,
      minSource,
      maxSource,
      minTooltipSource,
      maxTooltipSource,
    } = calculateMinMax(
      timeFrame,
      fieldIrrigationSystem,
      fieldSoilType,
      soilData,
      userUnits,
    );
    minSource = getSourceText(minSource);
    maxSource = getSourceText(maxSource);
    minTooltipContent = getTooltipText(
      minPlaceHolder,
      userUnits,
      minSource,
      minTooltipSource,
    );
    maxTooltipContent = getTooltipText(
      maxPlaceHolder,
      userUnits,
      maxSource,
      maxTooltipSource,
    );
    return {
      minPlaceHolder,
      maxPlaceHolder,
      minSource,
      maxSource,
      minTooltipContent,
      maxTooltipContent,
    };
  };

  const getSourceText = (source) => {
    if (source === 'soil') {
      return t('edit_field_irrigation_timeframe_source_soil');
    } else if (source === 'rate') {
      return t('edit_field_irrigation_timeframe_source_irrigation_rate');
    } else {
      return t('edit_field_irrigation_timeframe_source_user');
    }
  };

  const getTooltipText = (value, units, source, tooltipSource) => {
    if (source.includes('user')) {
      tooltipSource =
        tooltipSource === 'soil'
          ? t('soil_type')
          : t('edit_field_irrigation_timeframe_source_irrigation_rate');
      return (
        t('edit_field_irrigation_timeframe_tooltip') +
        value +
        ' ' +
        units +
        `(${t('source')}: ${tooltipSource})`
      );
    } else {
      return (
        t('edit_field_irrigation_timeframe_tooltip') +
        value +
        ' ' +
        units +
        `(${t('source')}: ${source})`
      );
    }
  };

  const handleChangeTimeFrameEndHour = (id, endHour) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.end_hour = endHour;
    if (item.day) {
      if (!HandleTimeFrameChange(timeFrames, item, 'edit')) {
        setEndHourChangeError(true);
        return true;
      }

      timeFramesDup[itemIndex] = item;
      setTimeFrames(timeFramesDup);
      onIrrigationTimeFramesChange(timeFramesDup);
      return false;
    }
  };

  const handleChangeMin = (id, min) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);

    item.min =
      item.max !== null &&
      min !== '' &&
      item.max !== '' &&
      parseFloat(min) > parseFloat(item.max)
        ? parseFloat(item.max) - 0.5
        : min;
    timeFramesDup[itemIndex] = item;
    setTimeFrames(timeFramesDup);
    onIrrigationTimeFramesChange(timeFramesDup);
  };

  const handleChangeMax = (id, max) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);

    item.max =
      item.min !== null &&
      max !== '' &&
      item.min !== '' &&
      parseFloat(item.min) > parseFloat(max)
        ? parseFloat(item.min) + 0.5
        : max;

    timeFramesDup[itemIndex] = item;
    setTimeFrames(timeFramesDup);
    onIrrigationTimeFramesChange(timeFramesDup);
  };
  const handleChangeStartDate = (id, startDate) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.active_period_start_date = startDate;
    timeFramesDup[itemIndex] = item;
    setTimeFrames(timeFramesDup);
    onIrrigationTimeFramesChange(timeFramesDup);
  };
  const handleChangeEndDate = (id, endDate) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.active_period_end_date = endDate;
    timeFramesDup[itemIndex] = item;
    setTimeFrames(timeFramesDup);
    onIrrigationTimeFramesChange(timeFramesDup);
  };
  const handleChangeName = (id, name) => {
    let timeFramesDup = [...timeFrames];
    let item = { ...timeFramesDup.find((timeFrame) => timeFrame.id === id) };
    let itemIndex = timeFramesDup.findIndex((timeFrame) => timeFrame.id === id);
    item.name = name;
    timeFramesDup[itemIndex] = item;
    setTimeFrames(timeFramesDup);
    onIrrigationTimeFramesChange(timeFramesDup);
  };

  const timeFramesElements = useMemo(() => {
    timeFrames.map((obj) => {
      if (obj.id === undefined) {
        obj.id = obj.day + obj.start_hour + obj.end_hour;
      }
      return obj;
    });
    return timeFrames.map((props, index) => {
      let {
        minPlaceHolder,
        maxPlaceHolder,
        minSource,
        maxSource,
        minTooltipContent,
        maxTooltipContent,
      } = setSourceContent(
        fieldIrrigationSystem,
        fieldSoilType,
        soilData,
        userUnits,
        props,
      );
      return (
        <div
          key={props.id}
          className='d-flex flex-column'
          style={{
            marginBottom: index === 0 ? 0 : '20px',
            marginTop: index === 0 ? 0 : '20px',
            borderBottom: '1px solid #DCE0E8',
          }}
        >
          <TimeFrame
            timeFrames={timeFrames}
            {...props}
            timeFrameSerialNumber={index + 1}
            onChangeDay={handleChangeTimeFrameDay}
            onChangeStartHour={handleChangeTimeFrameStartHour}
            onChangeEndDate={handleChangeEndDate}
            onChangeStartDate={handleChangeStartDate}
            onChangeEndHour={handleChangeTimeFrameEndHour}
            onChangeMin={handleChangeMin}
            onChangeMax={handleChangeMax}
            onChangeTimeFrameName={handleChangeName}
            onDelete={() => removeTimeFrame(props.id)}
            displayDeleteMenu={true}
            displayExpended={false}
            irrigationSystem={fieldIrrigationSystem}
            soilType={fieldSoilType}
            minPlaceHolder={minPlaceHolder}
            maxPlaceHolder={maxPlaceHolder}
            minSource={minSource}
            maxSource={maxSource}
            minTooltipContent={minTooltipContent}
            maxTooltipContent={maxTooltipContent}
            irrigationStartDate={irrigationStartDate}
          />
        </div>
      );
    });
  }, [timeFrames, fieldSoilType, fieldIrrigationSystem]);

  let {
    minPlaceHolder,
    maxPlaceHolder,
    minSource,
    maxSource,
    minTooltipContent,
    maxTooltipContent,
  } = setSourceContent(
    fieldIrrigationSystem,
    fieldSoilType,
    soilData,
    userUnits,
  );

  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-3 ml-2'>
        <div>
          <label className='text-grey45 text-size-16 mb-0 d-block font-weight-bold'>
            {t('irrigation_time_frames')}
          </label>
          <small className='text-grey71'>{t('define_time_frames')}</small>
        </div>
        <button
          type='button'
          className='btn btn-primary mr-2 blue-primary align-items-center d-flex justify-content-center'
          onClick={addNewTimeFrameHandler}
          disabled={addTimeFrameBtnClicked}
          style={{
            fontSize: 12,
            borderColor: addTimeFrameBtnClicked ? '#D0D3D9' : 'initial',
            background: addTimeFrameBtnClicked ? '#D0D3D9' : '#4563C7',
            height: '24px',
            width: '80px',
            padding: '3px 0px 3px',
          }}
        >
          <img
            src={addNewIcon}
            style={{ height: '12px', width: '12px' }}
            className='mx-1'
            alt='add new time frame'
          />
          Add new
        </button>
      </div>
      <div>
        {addTimeFrameBtnClicked ? (
          <EditTimeFrame
            irrigationStartDate={irrigationStartDate}
            timeFrameSerialNumber={timeFrames?.length + 1 || 1}
            onDone={addTimeFrame}
            onCancel={() => {
              setAddTimeFrameBtnClicked(!addTimeFrameBtnClicked);
            }}
            error={errorMessage}
            minSource={minSource}
            minTooltipContent={minTooltipContent}
            minPlaceHolder={minPlaceHolder}
            maxSource={maxSource}
            maxTooltipContent={maxTooltipContent}
            maxPlaceHolder={maxPlaceHolder}
          />
        ) : null}
        {timeFrames.length === 0 ? (
          <div
            className='d-flex bg-grey96 ml-2 mr-2 text-center justify-content-center'
            style={{
              height: '80px',
              padding: ' 0px 24px',
              lineHeight: '20px',
              top: '20px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                padding: '20px 0px 20px 0px',
                fontStyle: 'normal',
              }}
            >
              {t('no_time_frames')}
              <br />
              {t('for_this_field')}
            </div>
          </div>
        ) : null}
        {timeFramesElements}
      </div>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
  };
};
const mapDispatchToProps = {};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(IrrigationTimeFrame));
