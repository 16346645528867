const React = require('react');
const { useEffect, useState } = require('react');
const EditFertigationConcentration = require('EditFertigationConcentration');
const addNewIcon = require('images/icons/edit_field/icon_plus.svg');
const { useTranslation, withTranslation } = require('react-i18next');
const moment = require('moment');
const { connect } = require('react-redux');
const FertigationConcentrationsInputLine = require('FertigationConcentrationsInputLine');

const FertigationConcentrations = ({
  fcs,
  setIsAddFCButtonClicked,
  isAddFCButtonClicked,
  addFC,
  fcElements,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='d-flex align-items-center justify-content-between mb-1 mt-3 ml-2'>
        <div>
          <label className='text-grey45 text-size-16 mb-0 d-block font-weight-bold'>
            {t('fertigation_concentrations')}
          </label>
        </div>
        <button
          type='button'
          className='btn btn-primary blue-primary mr-2 align-items-center d-flex justify-content-center'
          onClick={() => {
            setIsAddFCButtonClicked(true);
          }}
          disabled={isAddFCButtonClicked}
          style={{
            fontSize: 12,
            borderColor: isAddFCButtonClicked ? '#D0D3D9' : 'initial',
            background: isAddFCButtonClicked ? '#D0D3D9' : '#4563C7',
            height: '24px',
            width: '80px',
            padding: '3px 0px 3px',
          }}
        >
          <img
            src={addNewIcon}
            style={{ height: '12px', width: '12px' }}
            className='mx-1'
            alt='add new fertigation concentration frame'
          />
          Add new
        </button>
      </div>
      <div>
        {isAddFCButtonClicked ? (
          <EditFertigationConcentration
            onDone={addFC}
            onCancel={() => {
              setIsAddFCButtonClicked(!isAddFCButtonClicked);
            }}
          />
        ) : null}
        {Object.keys(fcs).length === 0 ? (
          <div
            className='d-flex bg-grey96 ml-2 mr-2 text-center justify-content-center'
            style={{
              height: '80px',
              padding: ' 0px 24px',
              lineHeight: '20px',
              top: '20px',
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                padding: '20px 0px 20px 0px',
                fontStyle: 'normal',
              }}
            >
              {t('no_fertigation_concentrations_message')}
              <br />
              {t('for_this_field')}
            </div>
          </div>
        ) : (
          <div className='ml-2'>{fcElements()}</div>
        )}
      </div>
    </div>
  );
};

const InitialConcentrations = ({
  isModerator,
  handleInitialConcentrationChange,
  initial_concentrations,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className='form-row'>
        <div className='col-12'>
          <label className='ml-2 text-grey45 text-size-16 mb-0 d-block font-weight-bold'>
            {t('initial_concentrations')} (mg-N/l)
          </label>
        </div>
      </div>

      <div className='form-row mx-1'>
        <div className='col-4'>
          <label
            htmlFor='staticEmail'
            className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'
          >
            NH4
          </label>
          <input
            type='number'
            min='0'
            step='0.01'
            className='form-control form-control-sm'
            id='colFormLabelSm'
            disabled={isModerator}
            placeholder=''
            name='NH4_Concentration'
            value={initial_concentrations.NH4_Concentration || ''}
            onChange={(event) =>
              handleInitialConcentrationChange(
                event.target.value,
                event.target.name,
              )
            }
          />
        </div>
        <div className='col-4'>
          <label
            htmlFor='staticEmail'
            className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'
          >
            NO3
          </label>
          <input
            type='number'
            min='0'
            step='0.01'
            className='form-control form-control-sm'
            id='colFormLabelSm'
            disabled={isModerator}
            placeholder=''
            name='NO3_Concentration'
            value={initial_concentrations.NO3_Concentration || ''}
            onChange={(event) =>
              handleInitialConcentrationChange(
                event.target.value,
                event.target.name,
              )
            }
          />
        </div>
        <div className='col-4'>
          <label
            htmlFor='staticEmail'
            className='ml-0 mr-0 col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'
          >
            {t('n_in_organic_matter')} (g/m<sup>2</sup>)
          </label>
          <input
            type='number'
            min='0'
            step='0.01'
            className='form-control form-control-sm'
            id='colFormLabelSm'
            disabled={isModerator}
            placeholder=''
            name='N_Concenration_Organic_Matter'
            value={initial_concentrations.N_Concenration_Organic_Matter || ''}
            onChange={(event) =>
              handleInitialConcentrationChange(
                event.target.value,
                event.target.name,
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

const Fertigation = ({
  onFertigationConcentrationChange,
  onConcentrationChange,
  initialFertigationConcentrations,
  initial_concentrations,
  isModerator,
  shouldFertigationConcentrationBeDisabled,
}) => {
  const [isAddFCButtonClicked, setIsAddFCButtonClicked] = useState(false);
  const [fcs, setFcs] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setFcs(convertFCArrayToDict(initialFertigationConcentrations));
  }, [initialFertigationConcentrations]);

  const handleInitialConcentrationChange = (value, name) => {
    let newValue;

    // for the case that an input field stays empty
    if (value === '') {
      newValue = '';
    } else {
      newValue = parseFloat(value);
    }

    const updatedConcentration = { ...initial_concentrations };
    updatedConcentration[name] = newValue;
    onConcentrationChange(updatedConcentration);
  };

  const convertFCArrayToDict = (fcArray) => {
    let currentDict = Object.assign(
      {},
      ...fcArray.map((x) => ({
        [moment(x.start_date, 'YYYY-MM-DD').unix()]: {
          startDate: moment(x.start_date, 'YYYY-MM-DD'),
          fertType: x.fertType,
          concentration: parseFloat(x.concentration),
        },
      })),
    );

    return currentDict;
  };

  const addFC = (startDate, fertType, concentration) => {
    if (fertType && concentration) {
      const currentFC = {
        startDate: moment(startDate),
        fertType: fertType,
        concentration: concentration,
      };

      setIsAddFCButtonClicked(false);

      fcs[moment(startDate).unix()] = currentFC;
      onFertigationConcentrationChange(Object.values(fcs));
    }
  };

  const onStartDateChanged = (id, newStartDate) => {
    let newId = moment(newStartDate).unix();

    if (newId in fcs) {
      fcs[id].startDate = moment(id);
    } else {
      let newObject = fcs[id];
      newObject.startDate = moment(newStartDate);
      delete fcs[id];
      fcs[newId] = newObject;
    }

    onFertigationConcentrationChange(Object.values(fcs));
  };
  const onFTypeChanged = (id, newType) => {
    fcs[id].fertType = newType;
    onFertigationConcentrationChange(Object.values(fcs));
  };
  const onConcentrationChanged = (id, newConcentration) => {
    fcs[id].concentration = parseFloat(newConcentration);
    onFertigationConcentrationChange(Object.values(fcs));
  };
  const onDelete = (id) => {
    delete fcs[id];
    onFertigationConcentrationChange(Object.values(fcs));
  };

  const fcElements = () => {
    const FCTitles = (
      <div className='d-flex flex-row'>
        <div className='mr-2' style={{ flex: 1 }}>
          <label className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'>
            {t('start_date')}
          </label>
        </div>
        <div className='mr-2' style={{ flex: 1 }}>
          <label className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'>
            {t('end_date')}
          </label>
        </div>
        <div className='mr-2' style={{ flex: 1 }}>
          <label className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'>
            {t('type')}
          </label>
        </div>
        <div className='' style={{ flex: 1 }}>
          <label className='col-form-label col-form-label-sm justify-content-start text-grey45 font-weight-semi-bold'>
            {t('concentration')}
          </label>
        </div>
        <div className='' style={{ width: '32px' }}></div>
      </div>
    );

    let elements = Object.values(fcs).map((props, index, array) => {
      return (
        <div className='mb-2 mx-0'>
          <FertigationConcentrationsInputLine
            id={moment(props.startDate).unix()}
            startDate={moment(props.startDate).format('YYYY-MM-DD')}
            endDate={
              array[index + 1]
                ? moment(array[index + 1].startDate).format('YYYY-MM-DD')
                : t('not_yet_set')
            }
            fertType={props.fertType}
            concentration={props.concentration}
            onFTypeChanged={onFTypeChanged}
            onConcentrationChanged={onConcentrationChanged}
            onStartDateChange={onStartDateChanged}
            onDelete={onDelete}
          />
        </div>
      );
    });

    return [FCTitles].concat(elements);
  };

  return (
    <div>
      <label
        className='font-weight-bold text-size-20 mt-3 ml-2'
        htmlFor='fieldName'
      >
        {t('fertilization')}
      </label>

      <InitialConcentrations
        isModerator={isModerator}
        initial_concentrations={initial_concentrations}
        handleInitialConcentrationChange={handleInitialConcentrationChange}
      />
      {!shouldFertigationConcentrationBeDisabled && (
        <FertigationConcentrations
          fcs={fcs}
          addFC={addFC}
          fcElements={fcElements}
          isAddFCButtonClicked={isAddFCButtonClicked}
          setIsAddFCButtonClicked={setIsAddFCButtonClicked}
        />
      )}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
  };
};
const mapDispatchToProps = {};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Fertigation));
