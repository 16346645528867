const React = require('react');
const PlannerEntities = require('./PlannerEntities');
const fieldManager = require('@managers/fieldManager');
const { parseShortFloat, isEmptyObject } = require('@managers/helpers');
const { nitrogenInFert } = require('nitrogenManager');
var moment = require('moment-timezone');

export const DAYS_AMOUNT = 7;
const MINIMUM_DAYS_IN_SEASONAL_MODE = 175;
const DAY_TS = 60 * 60 * 24;

const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
export const colorMap = {
  irr: 'dark-blue',
  fert: 'dark-green',
  note: 'grey71',
  wtr: 'dark-orang-brown',
};
export const titlesMap = {
  phases: 'phase',
  note: 'notes',
  irr: 'irrigation',
  fert: 'fertilization',
  wtr: 'water treatment',
};
export const monthNames = [
  'months_january',
  'months_february',
  'months_march',
  'months_april',
  'months_may',
  'months_june',
  'months_july',
  'months_august',
  'months_september',
  'months_october',
  'months_november',
  'months_december',
];
export const typeToDataType = {
  note: 'note',
  irr: 'irrigation',
  fert: 'fertilization',
};
export const wtrSubTypeToTitle = {
  hpo: 'WT_timing_recommendation_last_hour',
  cs: 'WT_timing_recommendation_after_irrigation_starts',
  acid: 'WT_timing_recommendation_last_hour',
};

export const iconSrcMap = {
  phases: require('images/icons/planner/phases.svg'),
  note: require('images/icons/planner/note_icon.svg'),
  irr: require('images/icons/planner/irrigation_icon.svg'),
  fert: require('images/icons/planner/fertilization_icon.svg'),
  wtr: require('images/icons/planner/water_treatment.svg'),
};

export function getPageTitle(
  startDate,
  isWeekViewMode,
  t,
  startWithMonday = true,
) {
  let curr = new Date(startDate); // get current date
  let first = curr.getDate() - curr.getDay() + (startWithMonday ? 1 : 0); // First day is the day of the month - the day of the week

  let last = isWeekViewMode ? first + 6 : first + MINIMUM_DAYS_IN_SEASONAL_MODE; // last day is the first day + 6

  let firstDay = new Date(curr.setDate(first));
  let lastDay = new Date(curr.setDate(last));

  if (isWeekViewMode) {
    let crossMonthsWeek = firstDay.getMonth() !== lastDay.getMonth();
    return (
      t(monthNames[firstDay.getMonth()]) +
      (crossMonthsWeek ? ' - ' + t(monthNames[lastDay.getMonth()]) : '')
    );
  }
  let crossYearView = firstDay.getFullYear() !== lastDay.getFullYear();
  return (
    firstDay.getFullYear() +
    (crossYearView ? ' - ' + lastDay.getFullYear() : '')
  );
}

function initData(extraData, loopDate, filterCond) {
  return Object.keys(extraData)
    .filter((key) => filterCond(new Date(parseInt(key) * 1000), loopDate))
    .reduce((cur, key) => {
      return Object.assign(cur, { [key]: extraData[key] });
    }, {});
}

function getTextCell(loopDate, extraData, filterCond, type, isWeekViewMode) {
  let data = initData(extraData, loopDate, filterCond);

  if (type == 'phases') {
    let name = isEmptyObject(data) ? '' : Object.values(data)[0];

    return new PlannerEntities.Phase(
      typeof name != 'string' ? '' : name.toLowerCase(),
    );
  }
  if (type == 'note') {
    if (isWeekViewMode) {
      return new PlannerEntities.Note(
        isEmptyObject(data) ? '' : Object.values(data)[0],
        data,
      );
    }
    return new PlannerEntities.ValueCellObj(
      'note',
      data,
      Object.keys(data).length || '',
    );
  }
}

function getValueCell(loopDate, extraData, filterCond, type, subType) {
  let data = initData(extraData, loopDate, filterCond);

  let total = Object.values(data).reduce((accumulator, object) => {
    return accumulator + object.value;
  }, 0);

  total = parseShortFloat(total, 3, '');

  return new PlannerEntities.ValueCellObj(type, data, total, loopDate, subType);
}

export function getFirstDateInWeek(d, startWithMonday = true) {
  d = new Date(d);
  let day = d.getDay(),
    diff = d.getDate() - day + (startWithMonday ? (day == 0 ? -6 : 1) : 0); // when start with Monday -> adjust when day is sunday
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return new Date(d.setDate(diff));
}

const inCurrWeek = (date1, date2, startWithMonday) => {
  return areSameDates(
    getFirstDateInWeek(date1, startWithMonday),
    getFirstDateInWeek(date2, startWithMonday),
  );
};

export function getContent(
  OrigStartDate,
  isWeekViewMode,
  startWithMonday,
  requestedType,
  extraData = {},
  seasonStart = null,
  seasonEnd = null,
  fieldId = null,
  subType = null,
) {
  let content = [];
  let startDate = new Date(OrigStartDate);
  let today = new Date();
  let range = isWeekViewMode ? DAYS_AMOUNT : 50;
  let base = isWeekViewMode ? 1 : 7;
  let prevMonth, currMonth;
  let day = startDate.getDate();

  let filterCond = isWeekViewMode ? areSameDates : inCurrWeek;
  let currCellObj = undefined;
  let isPast;
  while (content.length < range) {
    let loopDate = new Date(startDate.getFullYear(), startDate.getMonth(), day);
    if (isWeekViewMode) {
      let isSelected = areSameDates(loopDate, today);
      isPast = loopDate < today && !isSelected;
      if (requestedType === 'dates') {
        currCellObj = new PlannerEntities.DayCellObj(
          days[loopDate.getDay()],
          loopDate.getDate(),
          isSelected,
        );
      } else if (requestedType === 'phases') {
        currCellObj = getTextCell(
          loopDate,
          extraData,
          areSameDates,
          requestedType,
        );
      } else if (requestedType === 'note') {
        currCellObj = getTextCell(
          loopDate,
          extraData,
          areSameDates,
          requestedType,
          isWeekViewMode,
        );
      } else {
        currCellObj = getValueCell(
          loopDate,
          extraData,
          areSameDates,
          requestedType,
          subType,
        );
      }
    } else {
      let isSelected = inCurrWeek(today, loopDate, startWithMonday);
      isPast = loopDate < today && !isSelected;
      if (requestedType === 'dates') {
        currMonth = monthNames[loopDate.getMonth()];
        let isNewMonth = prevMonth && prevMonth !== currMonth;
        currCellObj = new PlannerEntities.WeekCellObj(
          !prevMonth || isNewMonth ? currMonth : '',
          loopDate.getDate(),
          isSelected,
          isNewMonth,
          isPast,
        );

        prevMonth = currMonth;
      } else if (requestedType === 'phases') {
        currCellObj = getTextCell(
          loopDate,
          extraData,
          inCurrWeek,
          requestedType,
        );
      } else if (requestedType === 'note') {
        currCellObj = getTextCell(
          loopDate,
          extraData,
          inCurrWeek,
          requestedType,
          isWeekViewMode,
        );
      } else {
        currCellObj = getValueCell(
          loopDate,
          extraData,
          inCurrWeek,
          requestedType,
          subType,
        );
      }
    }
    currCellObj.isPast = isPast;
    currCellObj.fieldId = fieldId;
    currCellObj.startDate = loopDate.getTime();
    currCellObj.isWeekViewMode = isWeekViewMode;
    if (seasonStart && filterCond(loopDate, seasonStart)) {
      currCellObj.seasonBoundary = 'start';
    }
    if (seasonEnd && filterCond(loopDate, seasonEnd)) {
      currCellObj.seasonBoundary = 'end';
    }
    content.push(currCellObj);
    day += base;
  }
  return content;
}

export const areSameDates = (date1, date2) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
};

export const getTotal = (
  obj = {},
  startDate,
  isWeekViewMode,
  startWithMonday,
  seasonStart,
) => {
  let relevantKeys = Object.keys(obj);
  let from_date = isWeekViewMode ? startDate : seasonStart;
  relevantKeys = Object.keys(obj).filter((x) =>
    inCurrWeek(new Date(x * 1000), from_date, startWithMonday),
  );
  if (isWeekViewMode === false) {
    relevantKeys = Object.keys(obj).filter((key) => {
      let date = new Date(key * 1000);
      return date > new Date(from_date);
    });
  }
  let total = 0;
  for (let key of relevantKeys) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key].hasOwnProperty('value')) {
        total += obj[key].value;
      }
    }
  }

  return parseShortFloat(total, 2, 0);
};

export function differenceInHours(time1, time2) {
  if (!time1 || !time2) return null;
  return Math.abs(time1 - time2) / (60 * 60);
}

function ConvertFromFertilizationToNitrogen(fertilizationEvents) {
  let nitrogenEvents = {};
  Object.keys(fertilizationEvents).forEach((date) => {
    let currentFertilizationEvent = fertilizationEvents[date];
    let nitroAmount =
      currentFertilizationEvent.nitroAmount ||
      nitrogenInFert(
        currentFertilizationEvent.fertType,
        currentFertilizationEvent.value,
      );
    nitrogenEvents[date] = {
      nitroAmount: 1 * parseFloat(nitroAmount).toFixed(2),
      fertType: currentFertilizationEvent.fertType,
      value: 1 * parseFloat(currentFertilizationEvent.value).toFixed(2),
      source: currentFertilizationEvent.source,
      endDate:
        currentFertilizationEvent.endDate ||
        (Number(date) + 60 * 60 * 4).toString(),
      units: currentFertilizationEvent.units,
    };
  });
  return nitrogenEvents;
}

export function getSeasonStartDay(field) {
  return field.crop_data.irrigation_start_date
    ? moment(field.crop_data.irrigation_start_date)
    : moment(field.crop_data.sow_date);
}

export const initPhasesForField = (field, addToFieldsData) => {
  if (!field) {
    return;
  }
  let phasesItem = {};
  let seasonBoundary = {};
  const phases = field.crop_data.kc_table.data.byGrowthPhase;
  const seasonStart = getSeasonStartDay(field);
  if (!isEmptyObject(phases)) {
    let phaseStartDate = seasonStart.unix();
    phasesItem[seasonStart.unix()] = {};
    for (const phaseIndex in phases) {
      let phaseData = phases[phaseIndex];
      phasesItem[phaseStartDate] = phaseData.name;
      phaseStartDate += DAY_TS * phaseData.duration;
    }
    seasonBoundary = {
      seasonStart: seasonStart.toDate(),
      seasonEnd: new Date(phaseStartDate * 1000),
    };
  } else {
    let harvestDate = moment(field.crop_data.harvest_date || new Date()).unix();
    seasonBoundary = {
      seasonStart: seasonStart.toDate(),
      seasonEnd: new Date(harvestDate * 1000),
    };
  }

  addToFieldsData({ seasonBoundary: seasonBoundary, phases: phasesItem });
};

export const initPlannedForField = (field, addToFieldsData) => {
  if (!field) {
    return;
  }
  let plannedItems = {};
  plannedItems.irr = {};
  plannedItems.irr['h2o'] = {};
  let dailyData = field.historical_data;
  if ('plan_irr' in dailyData) {
    let copyOfStartDatesWFR =
      dailyData && 'plan_irr' in dailyData
        ? JSON.parse(JSON.stringify(dailyData.plan_irr.values))
        : {}; // we create copy of the values so we could remove values after we add them

    plannedItems.irr['h2o'] = copyOfStartDatesWFR;
  }
  if ('plan_fert' in dailyData) {
    const copyOfStartDateFertilization =
      'plan_fert' in dailyData
        ? JSON.parse(JSON.stringify(dailyData['plan_fert'].values))
        : {}; // we create copy of the values so we could remove values after we add them
    const startDateNitrogen = ConvertFromFertilizationToNitrogen(
      copyOfStartDateFertilization,
    );
    const allFerts = groupBy(startDateNitrogen, 'fertType');

    plannedItems.fert = allFerts;
  }
  if ('plan_wtr' in dailyData) {
    let copyOfStartDatesWTR =
      dailyData && 'plan_wtr' in dailyData
        ? JSON.parse(JSON.stringify(dailyData.plan_wtr.values))
        : {}; // we create copy of the values so we could remove values after we add them

    plannedItems.wtr = groupBy(copyOfStartDatesWTR, 'type');
  }
  if ('plan_note' in dailyData) {
    plannedItems.note = dailyData['plan_note'].values;
  }

  addToFieldsData({ plannedItems: plannedItems });
};

function groupBy(object, type) {
  let allTypes = {};
  for (let record in object) {
    let currSubType = object[record][type];
    if (!(currSubType in allTypes)) {
      allTypes[currSubType] = {};
      allTypes[currSubType].units = object[record].units;
    }
    allTypes[currSubType][record] = object[record];
  }
  return allTypes;
}

export const initUsedForField = (field, addToFieldsData) => {
  if (!field) {
    return;
  }
  let dailyData = field.historical_data;
  let usedItems = {};
  if ('wfr' in dailyData) {
    let copyOfStartDatesWFR =
      dailyData && 'wfr' in dailyData
        ? JSON.parse(JSON.stringify(dailyData.wfr_length.values))
        : {}; // we create copy of the values so we could remove values after we add them
    usedItems.irr = {};
    usedItems.irr['h2o'] = copyOfStartDatesWFR;
  }
  if ('fert' in dailyData) {
    const copyOfStartDateFertilization =
      'fert' in dailyData
        ? JSON.parse(JSON.stringify(dailyData['fert'].values))
        : {}; // we create copy of the values so we could remove values after we add them
    const startDateNitrogen = ConvertFromFertilizationToNitrogen(
      copyOfStartDateFertilization,
    );
    const allFerts = groupBy(startDateNitrogen, 'fertType');

    usedItems.fert = allFerts;
  }
  if ('wtr' in dailyData) {
    let copyOfStartDatesWTR =
      dailyData && 'wtr' in dailyData
        ? JSON.parse(JSON.stringify(dailyData.wtr.values))
        : {}; // we create copy of the values so we could remove values after we add them
    usedItems.wtr = groupBy(copyOfStartDatesWTR, 'type');
  }
  addToFieldsData({ usedItems: usedItems });
};

export function shouldWeekStartWithMonday(distributionName) {
  if (distributionName) {
    return !(distributionName.includes('Israel') || distributionName === 'ABC'); // dev
  }
  return false;
}

export function comparePlannerTypes(type1, type2) {
  const typesPriority = {
    phases: 0,
    note: 1,
    irr: 2,
    wtr: 2,
    fert: 3,
  };
  return typesPriority[type1] < typesPriority[type2] ? -1 : 1;
}
